import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { Image } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { ll } from '../styles/ll'
import { responsibleSwap } from '../styles/responsible-swap'
import { Mbr } from '../components/br'

const Outer = styled.div`
  background-color: #E9FFE6;
  @media (max-width: 50rem){
    margin-top: ${ll(0)};
  }
`

const Inner = styled.div`
  ${pageMaxWidth}
  padding-block: ${ll(37)};
  @media (max-width: 50rem){
    padding-block: ${ll(10)};
    
  }
`

const Container1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${ll(37)};
  padding-inline: ${ll(44)};
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
    padding-inline: ${ll(5)};
    gap: ${ll(16)};
  }
`

const Container1Item = styled.div`
padding: ${ll(17)};
background-color: white;
@media (max-width: 50rem){
  padding: ${ll(10)};
}
`

const Container1Head = styled.div`
  img {
    width: 100%;
    height: auto;
    display: block;
    ${responsibleSwap}
    @media (max-width: 50rem){
      
    }
  }
`

const Container1Img = styled.div`

`

const Container1Button = styled.a`
font-size: ${ll(23)};
padding: ${ll(8)} ${ll(24)} ${ll(4)};
line-height: normal;
color: white;
display: flex;
flex-direction: column;
font-weight: 700;
background-color: #7046B3;
margin-top: ${ll(20)};
align-items: center;
width: fit-content;
margin-inline: auto;
border-radius: 100rem;
transition: opacity 0.4s;
&:hover {
  opacity: 0.5;
}
@media (max-width: 50rem){
  font-size: ${ll(16)};
line-height: normal;
font-weight: 700;
margin-top: ${ll(8)};
padding: ${ll(2)} ${ll(24)} ${ll(4)};
}


`

const Container2 = styled.div`
  margin-top: ${ll(70)};
  background-color: #fff;
  position: relative;
  padding: ${ll(62)} 0 ${ll(20)};
  margin-inline: ${ll(44)};
  @media (max-width: 50rem){
    padding: ${ll(62)} 0 ${ll(20)};
    margin-top: ${ll(50)};
    margin-inline: ${ll(5)};
  }
`

const Container2Head = styled.div`
  position: absolute;
  top: ${ll(-24)};
  width: ${ll(936)};
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 50rem){
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    ${responsibleSwap}
    @media (max-width: 50rem){
      
    }
  }
`

const Container2Text1 = styled.div`
font-size: ${ll(23)};
text-align: center;
@media (max-width: 50rem){
  font-size: ${ll(18)};
line-height: normal;
font-weight: 700;
margin-top: ${ll(-8)};

}
`

const Container2Text2 = styled.div`
position: relative;
font-size: ${ll(30.807300567626953)};
vertical-align: baseline;
margin-left: auto;
margin-right: auto;
width: fit-content;
font-weight: 700;
margin-top: ${ll(20)};
text-align: center;
@media (max-width: 50rem){
  font-size: ${ll(22.192359924316406)};
line-height: normal;
font-weight: 700;
margin-top: ${ll(8)};
}
.sub {
  position: absolute;
  font-size: ${ll(12)};
  left: 0;
  top: 0;
  @media (max-width: 50rem){
    font-size: ${ll(10)};
line-height: normal;
font-weight: 700;
top: ${ll(-6)};
  }
}
small {
  font-size: 0.7em;
  opacity: 1;
  @media (max-width: 50rem){
    font-size: ${ll(15.665189743041992)};
line-height: normal;
font-weight: 700;
  }
}
strong {
  font-size: ${ll(53.60060119628906)};
color:#FF4F6B;
@media (max-width: 50rem){
  font-size: ${ll(38.61177062988281)};
line-height: normal;
font-weight: 700;
display: block;
margin-left: ${ll(-20)};
margin-top: ${ll(-20)};
}
small {
  font-size: ${ll(31.383800506591797)};
  color: #3D3D3D;
  @media (max-width: 50rem){
    font-size: ${ll(22.60763931274414)};
line-height: normal;
font-weight: 700;
  }
}
}
del {
  font-size: ${ll(36.243900299072266)};
  color: #FF4F6B;
  @media (max-width: 50rem){
    font-size: ${ll(26.108659744262695)};
line-height: normal;
font-weight: 700;
  }
}
.arrow {
  margin-inline: ${ll(10)};
  @media (max-width: 50rem){
    display: block;
    transform: rotate(90deg);
    margin-top: ${ll(-10)};
  }
}
`

const Container2Text3 = styled.div`
font-size: ${ll(16)};
color: #FF5F6F;
font-weight: bold;
margin-top: ${ll(30)};
text-align: center;
@media (max-width: 50rem){
  margin-top: 0;
  font-size: ${ll(14)};
line-height: normal;
text-align-last: left;
}
`

const Container2Text4 = styled.div`
font-size: ${ll(14)};
margin-top: ${ll(10)};
text-align: center;
@media (max-width: 50rem){
  margin-top: 0;
  font-size: ${ll(11)};
line-height: normal;
text-align-last: left;
}
`

const Container3 = styled.div`
  margin-top: ${ll(70)};
  background-color: #fff;
  position: relative;
  padding: ${ll(62)} 0 ${ll(20)};
  margin-inline: ${ll(44)};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-inline: ${ll(78)};
  @media (max-width: 50rem){
    grid-template-columns: 1fr;
    margin-top: ${ll(50)};
    padding: ${ll(10)} 0 ${ll(20)};
    margin-inline: ${ll(10)};
    padding-inline: ${ll(10)};
  }
`

const Container3Head = styled.div`
position: absolute;
top: ${ll(-35)};
font-size: ${ll(27.253799438476562)};
background-color: #00CE9E;
padding: ${ll(12)} ${ll(70)};
color: white;
font-weight: 700;
left: 0;
right: 0;
margin-left: auto;
margin-right: auto;
width: fit-content;
@media (max-width: 50rem){
  
}
`

const Container3Inner = styled.div`
  @media (max-width: 50rem){
    display: contents;
  }
`

const Container3Text = styled.div`
font-size: ${ll(22)};
line-height: normal;
font-weight: 700;
margin-top: ${ll(10)};
@media (max-width: 50rem){
  font-size: ${ll(17)};
line-height: normal;
font-weight: 700;
text-align: center;
}
`

const Container3Button = styled.a`
font-size: ${ll(20)};
display: flex;
color: white;
background-color: #0035A1;
margin-top: ${ll(40)};
width: fit-content;
gap: ${ll(16)};
padding: ${ll(14)} ${ll(40)};
margin-left: auto;
margin-right: auto;
border-radius: 100rem;
font-weight: 700;
transition: opacity 0.4s;
&:hover {
  opacity: 0.5;
}
@media (max-width: 50rem){
  grid-row: 3;
  margin-top: ${ll(20)};
  
}
`

const Container3Img = styled.div`
  margin-top: ${ll(-60)};
  @media (max-width: 50rem){
    margin-top: 0;
  }
  img {
    width: 100%;
    @media (max-width: 50rem){
      
    }
  }
`

const Icon = styled.div`
  font-size: ${ll(20)};
  font-family: 'FontAwesome';
  transform: rotate(90deg);
  margin-top: ${ll(-4)};
  @media (max-width: 50rem){
    
  }
`
const Icon2 = styled.div`
  font-family: 'FontAwesome';
  @media (max-width: 50rem){
    
  }

`

type MainSectionProps = {

}
export const MainSection: FunctionComponent<MainSectionProps> = () => {
  return <Outer>
    <Inner>
      <Container1>
        <Container1Item>
          <Container1Head>
            <Image src="top/m1.webp" />
            <Image src="top/m1.webp" />
          </Container1Head>
          <Container1Button href="#g">
            がんリスク検査について
            <Icon></Icon>
          </Container1Button>
        </Container1Item>
        <Container1Item>
          <Container1Head>
            <Image src="top/m2.webp" />
            <Image src="top/m2m.webp" />
          </Container1Head>
          <Container1Button href="#m" style={{backgroundColor: '#0097F1'}}>
            認知リスク検査について
            <Icon></Icon>
          </Container1Button>
        </Container1Item>
      </Container1>
      <Container2>
        <Container2Head>
          <Image src="top/m3.webp" />
          <Image src="top/m3m.webp" />
        </Container2Head>
        <Container2Text1>
        がんリスク検査（最大９がん）＋<Mbr />認知リスク検査を同時に検査すると
        </Container2Text1>
        <Container2Text2>
        <span className="sub">通常価格</span>
        19,800<small>円</small>+19,800<small>円</small>=<del>39,600</del><small>円</small><span className="arrow">→</span><strong>24,200<small>円（税込）</small></strong>
        </Container2Text2>
        <Container2Text4>
          ※検査会場によっては、どちらかの検査を実施していない場合がございます。あらかじめご了承ください。
        </Container2Text4>
      </Container2>
      <Container3>
        <Container3Head>
          検査会場
        </Container3Head>
        <Container3Inner>
          <Container3Text>
            北海道から沖縄まで全国の提携医療機関で<br />
            検査を受けることができます。<br />
            クリニックで安心・安全に採血できます。
          </Container3Text>
          <Container3Button href="/access">
            検査会場詳細&MAP<Icon2></Icon2>
          </Container3Button>
        </Container3Inner>
        <Container3Inner>
          <Container3Img>
            <Image src="top/m4.webp" />
          </Container3Img>
        </Container3Inner>
      </Container3>
    </Inner>
  </Outer>
}
